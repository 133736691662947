import { ApiService, ApiSyncType } from "@incendium/api";
import { Box, Grid } from "@mui/material";
import Google_Ads_logo from "Assets/Images/ThirdParty/Google_Ads_logo.png";
import Bing_Ads_logo from "Assets/Images/ThirdParty/Bing_Ads_2016_logo.svg";
import Meta_Ads_logo from "Assets/Images/ThirdParty/Meta_lockup_positive primary_RGB.svg";
import Ecwid_logo from "Assets/Images/ThirdParty/Ecwid_LSLockup_BlackRGB_Ecwid_EN.svg";
import { useSyncClients } from "Hooks/useSyncClients";
import { motion } from "framer-motion";
import {
  BingAds,
  GoogleAds,
  SyncCard,
  MetaAds,
  Ecwid,
} from "features/dataConnectors";
import Loading from "Components/Loading/Loading";

const listVariants = {
  hidden: {
    opacity: 0,
    transition: {
      when: "afterChildren",
    },
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.15,
      when: "beforeChildren",
    },
  },
};

const itemVariants = {
  hidden: { scale: 0.7, opacity: 0 },
  visible: { scale: 1, opacity: 1 },
};

interface IDataConnectorsListProps {
  type: "platform" | "app";
}
function DataConnectorsList({ type }: IDataConnectorsListProps) {
  const { loading } = useSyncClients();

  if (loading) {
    return (
      <Box display={"flex"} my={4}>
        <Loading />
      </Box>
    );
  }

  return (
    <>
      <Grid
        container
        spacing={3}
        component={motion.div}
        variants={listVariants}
        initial="hidden"
        animate="visible"
      >
        {type === "platform" && (
          <>
            <Grid item xs={4} component={motion.div} variants={itemVariants}>
              <SyncCard
                logo={Google_Ads_logo}
                type={ApiSyncType.GOOGLE_ADS}
                title="Google Ads"
                description="Link with Google Ads to synchronize user visits from campaigns, retrieve cost data for ROAS calculations, and unlock additional insights."
                service={ApiService.GOOGLE}
                configComponent={GoogleAds}
              />
            </Grid>
            <Grid item xs={4} component={motion.div} variants={itemVariants}>
              <SyncCard
                logo={Bing_Ads_logo}
                type={ApiSyncType.BING_ADS}
                title="Bing Ads"
                description="Connect with Bing Ads to synchronize user visits from campaigns, access cost data for precise ROAS calculations, and gain valuable insights to optimize performance."
                service={ApiService.BING}
                configComponent={BingAds}
                imageSx={{ maxHeight: 30 }}
              />
            </Grid>
            <Grid item xs={4} component={motion.div} variants={itemVariants}>
              <SyncCard
                logo={Meta_Ads_logo}
                type={ApiSyncType.FACEBOOK_ADS}
                title="Meta / Facebook Ads"
                description="Connect with Meta Ads to synchronize user visits from campaigns across Facebook, Instagram, and Messenger, access cost data for precise ROAS calculations, and gain valuable insights to optimize performance."
                service={ApiService.FACEBOOK}
                configComponent={MetaAds}
                imageSx={{ transform: `translateX(-12px)` }}
              />
            </Grid>
          </>
        )}
        {type === "app" && (
          <>
            <Grid item xs={4} component={motion.div} variants={itemVariants}>
              <SyncCard
                logo={Ecwid_logo}
                type={ApiSyncType.ECWID_API}
                title="Ecwid"
                description="Connect with your ECWID store for more accurate tracking and ecommerce insights."
                service={ApiService.ECWID}
                configComponent={Ecwid}
                state={`${window.location.protocol}//${window.location.host}/oauth-sync`}
                imageSx={{ maxHeight: 32 }}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}

export default DataConnectorsList;
