import Train from "Assets/icons/Train-icon.png";
import Publish from "Assets/icons/Publish-icon.png";
import Audience from "Assets/icons/Audience-icon.png";
import Connect from "Assets/icons/Connect-icon.png";
import { useSelectedProject } from "Hooks";
import { matchPath, useLocation } from "react-router";
import { Box } from "@mui/material";
import SetupAnalyticsSwapper from "Components/SetupAnalyticsSwapper/SetupAnalyticsSwapper";
import { useSelector } from "react-redux";
import { leftMenuSelector } from "Selectors/leftMenuSelector";
import { AnimatePresence, motion } from "framer-motion";
import AccessLevel from "Components/AccessLevel/AccessLevel";
import { ApiACLRole } from "@incendium/api";
import {
  LeftMenuItem,
  LeftMenuListItem,
  AnalyticsMenu,
  LeftMenuBlock,
} from "features/leftMenu";

function ProjectMenu() {
  const { selectedProject, selectedClient } = useSelectedProject();
  const { isAnalysisActive } = useSelector(leftMenuSelector);

  const location = useLocation();

  const isConnect = matchPath(location.pathname, {
    path: "/clients/:clientId/projects/:projectId/connect",
    exact: false,
    strict: false,
  });
  const isTrain = matchPath(location.pathname, {
    path: "/clients/:clientId/projects/:projectId/train",
    exact: false,
    strict: false,
  });
  const isAudience = matchPath(location.pathname, {
    path: "/clients/:clientId/projects/:projectId/audience",
    exact: false,
    strict: false,
  });
  const isPublish = matchPath(location.pathname, {
    path: "/clients/:clientId/projects/:projectId/publish",
    exact: false,
    strict: false,
  });

  return (
    <>
      <AccessLevel role={ApiACLRole.PROJECT_OBSERVER} write>
        <LeftMenuBlock>
          <SetupAnalyticsSwapper />
        </LeftMenuBlock>
      </AccessLevel>

      <AnimatePresence mode="wait">
        {isAnalysisActive ? (
          <Box
            component={motion.div}
            key={"analyse"}
            initial={{
              x: 100,
              opacity: 0,
            }}
            animate={{
              x: 0,
              opacity: 1,
            }}
            exit={{ x: -100, opacity: 0 }}
            transition={{
              delay: 0,
            }}
          >
            <AnalyticsMenu />
          </Box>
        ) : (
          <AccessLevel role={ApiACLRole.PROJECT_OBSERVER} write>
            <Box
              component={motion.div}
              key={"project"}
              initial={{
                x: -100,
                opacity: 0,
              }}
              animate={{
                x: 0,
                opacity: 1,
              }}
              exit={{ x: 100, opacity: 0 }}
              transition={{
                delay: 0,
              }}
            >
              <LeftMenuItem
                text="connect"
                imagePath={Connect}
                to="#"
                active={!!isConnect}
              >
                <LeftMenuListItem
                  to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/connect/data-connections`}
                  text="Data Connections"
                />
                <LeftMenuListItem
                  to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/connect/subdomains`}
                  text="Subdomains"
                />
                <LeftMenuListItem
                  to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/connect/data-manager`}
                  text="Data Manager"
                />
              </LeftMenuItem>
              <LeftMenuItem
                text="train"
                imagePath={Train}
                to={"#"}
                active={!!isTrain}
              >
                <LeftMenuListItem
                  to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/train/alerts`}
                  text="Alerts"
                />
                <LeftMenuListItem
                  to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/train/traffic/channels`}
                  text="Traffic"
                />
                <LeftMenuListItem
                  to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/train/lead-scoring`}
                  text="Lead Scoring"
                />
                <LeftMenuListItem
                  to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/train/effectiveness`}
                  text="Effectiveness"
                />
                <LeftMenuListItem
                  to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/train/leads-status`}
                  text="Leads Status"
                />
                <LeftMenuListItem
                  to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/train/locations`}
                  text="Locations"
                />
                <LeftMenuListItem
                  to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/train/conversions`}
                  text="Conversions"
                />

                <LeftMenuListItem
                  to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/train/location-tags`}
                  text="Location Tags"
                />
              </LeftMenuItem>
              <LeftMenuItem
                text="publish"
                imagePath={Publish}
                to="#"
                active={!!isPublish}
              >
                <LeftMenuListItem
                  to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/publish/conversion-lab`}
                  text="Conversion Lab"
                />
                <LeftMenuListItem
                  to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/publish/rezonate`}
                  text="Rezonate"
                />
                <LeftMenuListItem
                  to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/publish/components`}
                  text="Components"
                />
                <LeftMenuListItem
                  to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/publish/lightning-landers`}
                  text="Lightning Landers"
                />
              </LeftMenuItem>

              <LeftMenuItem
                text="audience"
                imagePath={Audience}
                to="#"
                active={!!isAudience}
              >
                <LeftMenuListItem
                  to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/audience/traits`}
                  text="Audience Traits"
                />
                <LeftMenuListItem
                  to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/audience/audiences`}
                  text="Audiences"
                />
              </LeftMenuItem>
            </Box>
          </AccessLevel>
        )}
      </AnimatePresence>
    </>
  );
}

export default ProjectMenu;
